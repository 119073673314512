import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {  useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
//config url
import configData from "./../../config.js";

const PtnrBanner = (props) => {
    const { register, handleSubmit } = useForm()
    let history = useHistory();
  const submitForm = async (data) => {
  
    try {
      const response = await fetch(`${configData.ADMIN_URL}/api/contact`, {
        method: 'POST',
        body: JSON.stringify({
        "name": data.name,
        "email": data.email,
        "mobile":  data.mobile,
        "requirement":  data.requirement
        }),
        "headers": {
          "Accept": "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json", 
        },
      });
      const result = await response.json();
      
      if(result.data.success) {
   
        history.push('/thanks.php');
      }
      
    } catch (err) {
     // console.log(err.errors['mobile'])
      toast.error("Try again", {autoClose:3000})
    } 
  };
	const handleError = (errors) => {
    toast.error("All fields must be filled", { autoClose: 3000 })

  };
  const registerOptions = {
    name: { required: "required", },
    mobile: { required: "required", },
    email: { required: "required", },
    requirement: { required: "", }
  };
   
        return (
            <>
                <div className="sx-bnr-inr overlay-wraper bg-top-center for-mbl-op" data-stellar-background-ratio="0.5" style={{ backgroundImage: 'url(' + props.bgimage + ')',backgroundSize:'contain',paddingTop:100,paddingBottom:100 }}>
                    
					{/*<div className="overlay-main bg-black opacity-07" />*/}
                    <div className="container">
					
                        <div className="sx-bnr-inr-entry">
						
						<div className="baner-txt-and-form">
						
						 <div className="col-xl-6 col-lg-6 col-md-12">
						 
                            <div className="banner-title-outer">
                                <div className="banner-title-name ptr-all-bnr-txxt">
                                    {/* <h3 className="ptn-rbnner-txt">{props.title}</h3>                               
									<h3 className="ptn-rbnner-txt-1">{props.description}</h3>
									<h3 className="ptn-rbnner-txt-2">{props.title1}</h3> */}
                                </div>
                            </div>
							
							</div>
							
							<div className="col-xl-6 col-lg-6 col-md-12">
							
							
							 
                                    <div className="col-lg-8 col-md-12 col-sm-12 form-ext-col">
                                    {/* <img src='assets/images/Best-Architecture-Design-Services-Mobile.jpeg' alt='Best Architecture Design Services' className='desktop-hidden mobile_slide_img' />    */}
									 <div className="contact-home1-left contact-home1-left-v2 shadow-lg bg-white p-a30 cont-form-int-ero">
                                            <form  onSubmit={handleSubmit(submitForm, handleError)} className="" >
                                                <div className="input input-animate ptnr-form">
                                                    <label htmlFor="name">Name</label>
                                                    <input {...register('name', registerOptions.name) }  type="text" />
                                                    <span className="spin" />
                                                </div>
                                                <div className="input input-animate ptnr-form">
                                                    <label htmlFor="email">Email</label>
                                                    <input {...register('email', registerOptions.email) } type="email"  />
                                                    <span className="spin" />
                                                </div>
                                                <div className="input input-animate ptnr-form">
                                                    <label htmlFor="Phone">Phone</label>
                                                    <input {...register('mobile', registerOptions.mobile) } type="text"  />
                                                    <span className="spin" />
                                                </div>
                                                <div className="form-group ptnr-form-textarea">
                                                    <label htmlFor="requirement">Plot Size & requirements</label>
                                                    <textarea {...register('requirement', registerOptions.requirement) } className="form-textarea" placeholder="Enter Plot Size & requirement"></textarea>
                                                    
                                                    <span className="spin" />
                                                </div>
                                               
                                                <div className="text-left">
                                                    <button type="submit" className="site-button-secondry btn-half" style={{width:"100%",padding:10}}>
                                                        <span style={{letterSpacing:"normal"}}>Submit</span>
                                                    </button>
                                                </div>
												
												<p className="ptr-form-accept-plcy">By Submitting this form, you agree to the privacy policy and terms of use.</p>
												
                                            </form>
											
                                    </div>
                                </div>
							
							
							</div>
							
							</div>
							<ToastContainer /> 
                            {/* BREADCRUMB ROW */}
								{/*<div>
                                <ul className="sx-breadcrumb breadcrumb-style-2">
                                <li><NavLink to={"./"}>Home</NavLink></li>
                                    <li>{this.props.pagename}</li>
                                </ul>
	                        </div>*/}
                            {/* BREADCRUMB ROW END */}
                        </div>
						
                    </div>
                </div>
            </>
        );
  
};

export default PtnrBanner;